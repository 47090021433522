import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SensorService {

  private baseuri='http://ec2-54-236-157-192.compute-1.amazonaws.com:3000';
  private hedears=new HttpHeaders().set('content-type','application/json');
  constructor(private http: HttpClient) { }
  readtemp(){
    return this.http.get(this.baseuri+'/gettempdata',{headers:this.hedears}).toPromise().then((data) => {
      return data;
    });
      }
  readhum(){
        return this.http.get(this.baseuri+'/gethumdata',{headers:this.hedears}).toPromise().then((data) => {
          return data;
        });
          }
          readco2(){
            return this.http.get(this.baseuri+'/getco2data',{headers:this.hedears}).toPromise().then((data) => {
              return data;
            });
              }
          readtvoc(){
                return this.http.get(this.baseuri+'/gettvocdata',{headers:this.hedears}).toPromise().then((data) => {
                  return data;
                });
                  }
          getSensorDataByDate(selectedDate: Date,selectedDate1: Date) {
            return this.http.get(`${this.baseuri+'/data'}?date=${selectedDate.toISOString()}&date2=${selectedDate1.toISOString()}`).toPromise().then((data) => {
              return data;
            });
          }
          getSensorData2ByDate(selectedDate: Date,selectedDate1: Date) {
            return this.http.get(`${this.baseuri+'/data2'}?date=${selectedDate.toISOString()}&date2=${selectedDate1.toISOString()}`).toPromise().then((data) => {
              return data;
            });
          }
          getSensorData3ByDate(selectedDate: Date,selectedDate1: Date) {
            return this.http.get(`${this.baseuri+'/data2'}?date=${selectedDate.toISOString()}&date2=${selectedDate1.toISOString()}`).toPromise().then((data) => {
              return data;
            });
          }
          readavr(selectedDate: Date){
            return this.http.get(`${this.baseuri+'/data3'}?date=${selectedDate.toISOString()}`).toPromise().then((data) => {
              return data;
            });
            
              }
}
