import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sensor } from './data';
import { Sensor2 } from './data2';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  private baseuri='http://ec2-54-236-157-192.compute-1.amazonaws.com:3000';

  private hedears=new HttpHeaders().set('content-type','application/json');
    constructor(private http:HttpClient) { }

  readdata():Observable<Sensor[]>{
    return this.http.get<Sensor[]>(this.baseuri+'/sensor',{headers:this.hedears});
      }
      readdata2():Observable<Sensor2[]>{
        return this.http.get<Sensor2[]>(this.baseuri+'/sensor2',{headers:this.hedears});
          }
}
